import { default as developerbluzPcVKLwMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as _91organizationId_93IyQLJrQ1yAMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as indexuGbnXFMwdmMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as passkeyz6pjeSBx9kMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/passkey.vue?macro=true";
import { default as perksTQ6Tr3381zMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as addressqi3RB79NiiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/address.vue?macro=true";
import { default as certificationsr9wEBngkysMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/certifications.vue?macro=true";
import { default as profiletMFCMVB0eGMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/profile.vue?macro=true";
import { default as socialDb8Pdl3RQ2Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/social.vue?macro=true";
import { default as persona3KVGW7MKkxMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona.vue?macro=true";
import { default as referralDHtUpDLLYsMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as securityY7dJC2aCgmMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as _91uuid_932ILfwuIQY6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_933mOtiLKSnaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45wallet7HY24xD7C2Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binancevFVLvu3VY6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbaseuTVo0PvE4eMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdwOe5JS9LQTMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletILCYHGS7vRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as forexk5roFBgAXTMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as international_45usd_45manualomImgONrDnMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-manual.vue?macro=true";
import { default as international_45usd5b6Xy2iTPMMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenbnd68fy3VzMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as addfUaSYg6p0kMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexredzMCArU7Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardhoCZaAlCnFMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as documents98CZsbbxC5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/documents.vue?macro=true";
import { default as completePfsj3rA96OMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingyjsvzySkZnMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_93hVyrqgtmAgMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/[id].vue?macro=true";
import { default as indexJhDU2c16W5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/index.vue?macro=true";
import { default as indexuhoHwvUX0ZMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/index.vue?macro=true";
import { default as _91id_93isibkFWBCoMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/[id].vue?macro=true";
import { default as indexXkfvmgR0aRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/index.vue?macro=true";
import { default as tasksii7mTIV9KlMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as cryptoGhIVfntt38Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiath4OrUfYJmIMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directZvHJvjgZbWMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdraw8YvM7voYwtMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as benefitsDjDh6KWPp6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboard6863mWSf1CMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as documentsK5VcZHivFWMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/documents.vue?macro=true";
import { default as pay_45slips7BC0ftjvy0Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as reimbursementsCxEmpogPtEMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksQE1ALjpkRNMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as withdrawtR92IUqjjDMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as indexpP5y7gaEk8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_93TprT5TgYa2Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtf1wvPMIheVMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as activity0Tg4ABnC6cMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/activity.vue?macro=true";
import { default as administrators6v5gFk4EmJMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboarddNN4S69uTPMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as documentssoWdHcoKReMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/documents.vue?macro=true";
import { default as completefhelDnf0gDMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pending1Chu6jf0CqMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directB0dGVaqMvuMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmUx2JLT4yWYMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesi0nWzpjGNEMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as addressmMhtMyCybWMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/address.vue?macro=true";
import { default as detailsWWn9glGZZqMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/details.vue?macro=true";
import { default as ownershipAP0Pbn9m27Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/ownership.vue?macro=true";
import { default as settingskgrgAUrh3QMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/settings.vue?macro=true";
import { default as profileGmvUzhfujiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile.vue?macro=true";
import { default as subscription4hHDfDyN9KMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as taskspk0j9uvLRZMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addjImwshw5TVMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as indexnhGli6o8WiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transfer9XT1EnrLc6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45address4wmufxkse8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wireuLRahvjeIiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositJGyfSLVqzaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundIthxdXIkwdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93oyo1zNPSd5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as externalzfb0l7FEe8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2faeAh0NXs9qdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as code9qsw6C7LGCMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indexM8j8nwKafgMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45outYOOeDrhrWRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codeVJY9pEN7uPMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexaBiM5Y3sg5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingTKd2WqyGrrMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as sign7swIHUxnl3Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign.vue?macro=true";
import { default as dashboard4col0FVWjhMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as blockchain_45addresswWTCcspM56Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wirerRP9pBU2waMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as depositI8i0WNfGBDMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as documentsHDA2EUXxUhMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/documents.vue?macro=true";
import { default as completeahUUi2YZm7Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingpWCx2ygxvRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as batchaSayFoH7bPMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directrLSFtGghPvMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmaDA5M5fv7wMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invites1nSHJTMgBjMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as invoicesRJPp5FCQohMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invoices.vue?macro=true";
import { default as benefitsiCChJxcJigMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summaryEDTrLS0655Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_93IGAEgZ2vMsMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexfKe9EuJmezMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csviXC7FjlNHEMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as indexhhYjDlM5jaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexhsNgeqYdaOMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directykRmfyj6PdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as hourlyel136TZ2CMMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/hourly.vue?macro=true";
import { default as instantjzBTMMIGWUMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestone64vdcxkIqcMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as oncenXPePqEilkMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringQ2vORSSXWqMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93JYwPh4w0XaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payrollniQO6DfrVkMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue?macro=true";
import { default as settingsrRTwS6Lv3GMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasks3YgTinrAVNMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerbluzPcVKLwMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue")
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93IyQLJrQ1yAMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue")
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: indexuGbnXFMwdmMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue")
  },
  {
    name: "account-passkey",
    path: "/account/passkey",
    meta: passkeyz6pjeSBx9kMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/passkey.vue")
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksTQ6Tr3381zMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue")
  },
  {
    name: "account-persona",
    path: "/account/persona",
    meta: persona3KVGW7MKkxMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona.vue"),
    children: [
  {
    name: "account-persona-address",
    path: "address",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/address.vue")
  },
  {
    name: "account-persona-certifications",
    path: "certifications",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/certifications.vue")
  },
  {
    name: "account-persona-profile",
    path: "profile",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/profile.vue")
  },
  {
    name: "account-persona-social",
    path: "social",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/social.vue")
  }
]
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralDHtUpDLLYsMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityY7dJC2aCgmMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue")
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue")
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue")
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: addfUaSYg6p0kMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue"),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-manual",
    path: "international-usd-manual",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-manual.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue")
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue")
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexredzMCArU7Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue")
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardhoCZaAlCnFMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue")
  },
  {
    name: "contractors-workspaceId-documents",
    path: "/contractors/:workspaceId()/documents",
    meta: documents98CZsbbxC5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/documents.vue")
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: completePfsj3rA96OMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue")
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingyjsvzySkZnMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue")
  },
  {
    name: "contractors-workspaceId-invoices-flat-id",
    path: "/contractors/:workspaceId()/invoices/flat/:id()",
    meta: _91id_93hVyrqgtmAgMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/[id].vue")
  },
  {
    name: "contractors-workspaceId-invoices-flat",
    path: "/contractors/:workspaceId()/invoices/flat",
    meta: indexJhDU2c16W5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/index.vue")
  },
  {
    name: "contractors-workspaceId-invoices",
    path: "/contractors/:workspaceId()/invoices",
    meta: indexuhoHwvUX0ZMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/index.vue")
  },
  {
    name: "contractors-workspaceId-invoices-time-id",
    path: "/contractors/:workspaceId()/invoices/time/:id()",
    meta: _91id_93isibkFWBCoMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/[id].vue")
  },
  {
    name: "contractors-workspaceId-invoices-time",
    path: "/contractors/:workspaceId()/invoices/time",
    meta: indexXkfvmgR0aRMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/index.vue")
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksii7mTIV9KlMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue")
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdraw8YvM7voYwtMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue"),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue")
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue")
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue")
  }
]
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsDjDh6KWPp6Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue")
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboard6863mWSf1CMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue")
  },
  {
    name: "employees-workspaceId-documents",
    path: "/employees/:workspaceId()/documents",
    meta: documentsK5VcZHivFWMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/documents.vue")
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slips7BC0ftjvy0Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue")
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsCxEmpogPtEMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue")
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksQE1ALjpkRNMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue")
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdrawtR92IUqjjDMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexpP5y7gaEk8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue")
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_93TprT5TgYa2Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue")
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue")
  },
  {
    name: "organizations-workspaceId-activity",
    path: "/organizations/:workspaceId()/activity",
    meta: activity0Tg4ABnC6cMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/activity.vue")
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administrators6v5gFk4EmJMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue")
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboarddNN4S69uTPMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue")
  },
  {
    name: "organizations-workspaceId-documents",
    path: "/organizations/:workspaceId()/documents",
    meta: documentssoWdHcoKReMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/documents.vue")
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: completefhelDnf0gDMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue")
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pending1Chu6jf0CqMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue")
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: invitesi0nWzpjGNEMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue"),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue")
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue")
  }
]
  },
  {
    name: "organizations-workspaceId-profile",
    path: "/organizations/:workspaceId()/profile",
    meta: profileGmvUzhfujiMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile.vue"),
    children: [
  {
    name: "organizations-workspaceId-profile-address",
    path: "address",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/address.vue")
  },
  {
    name: "organizations-workspaceId-profile-details",
    path: "details",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/details.vue")
  },
  {
    name: "organizations-workspaceId-profile-ownership",
    path: "ownership",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/ownership.vue")
  },
  {
    name: "organizations-workspaceId-profile-settings",
    path: "settings",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/profile/settings.vue")
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscription4hHDfDyN9KMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue")
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: taskspk0j9uvLRZMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue")
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addjImwshw5TVMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue")
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: indexnhGli6o8WiMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue")
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transfer9XT1EnrLc6Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue")
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositJGyfSLVqzaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue"),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45address4wmufxkse8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue")
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wireuLRahvjeIiMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue")
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue")
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93oyo1zNPSd5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue")
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: externalzfb0l7FEe8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue")
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue")
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: code9qsw6C7LGCMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexM8j8nwKafgMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue")
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue")
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codeVJY9pEN7uPMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexaBiM5Y3sg5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue")
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingTKd2WqyGrrMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue")
  },
  {
    name: "sign",
    path: "/sign",
    meta: sign7swIHUxnl3Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign.vue")
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboard4col0FVWjhMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue")
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: depositI8i0WNfGBDMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue"),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addresswWTCcspM56Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue")
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wirerRP9pBU2waMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue")
  }
]
  },
  {
    name: "teams-workspaceId-documents",
    path: "/teams/:workspaceId()/documents",
    meta: documentsHDA2EUXxUhMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/documents.vue")
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: completeahUUi2YZm7Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue")
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pendingpWCx2ygxvRMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue")
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invites1nSHJTMgBjMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue"),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue")
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue")
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue")
  }
]
  },
  {
    name: "teams-workspaceId-invoices",
    path: "/teams/:workspaceId()/invoices",
    meta: invoicesRJPp5FCQohMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invoices.vue")
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_93IGAEgZ2vMsMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue"),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue")
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue")
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexfKe9EuJmezMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue")
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csviXC7FjlNHEMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue")
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: indexhhYjDlM5jaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue")
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexhsNgeqYdaOMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93JYwPh4w0XaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue"),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directykRmfyj6PdMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-hourly",
    path: "hourly",
    meta: hourlyel136TZ2CMMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/hourly.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instantjzBTMMIGWUMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestone64vdcxkIqcMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: oncenXPePqEilkMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue")
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringQ2vORSSXWqMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue")
  }
]
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: payrollniQO6DfrVkMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue")
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsrRTwS6Lv3GMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue")
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasks3YgTinrAVNMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue")
  }
]