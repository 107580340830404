import { useCookies } from "@vueuse/integrations/useCookies";
import type { Scope } from "~/stores/api/relationships";

function hasSession() {
  const sessionStore = useSessionStore();
  const clerkUserStore = useClerkUserStore();

  const userCookie = useCookies([]);
  const userCookieValue = userCookie.get("rise-user");
  if (userCookieValue) {
    clerkUserStore.user = userCookieValue;
  }

  return userCookieValue || sessionStore.hasSession();
}

export default defineNuxtRouteMiddleware(async (to) => {
  if (!hasSession) return;
  if (!to.params.workspaceId) return;

  const scope = to.path.split("/")[1];
  if (!scope) return;

  const relationshipsStore = useRelationshipsStore();

  try {
    if (relationshipsStore.workspaces.length <= 0) {
      await relationshipsStore.getRelationships();
    }
  } catch (e) {
    return;
  }

  const availableScopes: Scope[] = [
    "contractors",
    "employees",
    "organizations",
    "teams",
  ];

  if (!availableScopes.includes(scope as Scope)) return;

  let hasPermission = false;

  relationshipsStore.workspaces.forEach((workspace) => {
    const workspaceScope = relationshipsStore.roleScopes[workspace.type];
    const id = workspace.nanoid;

    if (scope === workspaceScope && id === to.params.workspaceId) {
      hasPermission = true;
    }

    if (!workspace.teams) return;

    workspace.teams.forEach((team) => {
      const teamScope = relationshipsStore.roleScopes[team.type];
      const teamId = team.nanoid;

      if (scope === teamScope && teamId === to.params.workspaceId) {
        hasPermission = true;
      }
    });
  });

  if (!hasPermission) {
    return navigateTo("/");
  }
});
