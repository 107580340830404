import revive_payload_client_t4MtB98cSo from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vyvtI4suc1 from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iRnaLnmtfo from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_J9CRviSnY0 from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tRslz3pRJa from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hpeKj9JrvH from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hNvDMdvFUT from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QabopUW0M6 from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.4.5_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_cP1LrUfoxJ from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/.nuxt/floating-vue.mjs";
import apexCharts_client_ILYcaWERKg from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/apexCharts.client.ts";
import clerk_client_82tfzadmlf from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/clerk.client.ts";
import dateFns_TubOpgHRRM from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/dateFns.ts";
import fets_ijYEFpGoED from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/fets.ts";
import filters_VNgPjx6zHj from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/filters.ts";
import flags_p3tEupiaZS from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/flags.ts";
import icons_sncVX745v6 from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/icons.ts";
import lottie_q1asFBKAuo from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/lottie.ts";
import mask_Tk3SUcMqzt from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/mask.ts";
import money_JsSmk5CwyU from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/money.ts";
import pinia_client_l2mvTNw9P0 from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/pinia.client.ts";
import places_wGZnNGyAIQ from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/places.ts";
import pusher_T6fDwDIeLY from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/pusher.ts";
import recaptcha_85gNSCNFUU from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/recaptcha.ts";
import select_tI36lTGDUc from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/select.ts";
import vCalendar_client_FEmB2rjZFJ from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/plugins/vCalendar.client.ts";
export default [
  revive_payload_client_t4MtB98cSo,
  unhead_vyvtI4suc1,
  router_iRnaLnmtfo,
  payload_client_J9CRviSnY0,
  navigation_repaint_client_tRslz3pRJa,
  check_outdated_build_client_hpeKj9JrvH,
  chunk_reload_client_hNvDMdvFUT,
  plugin_vue3_QabopUW0M6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_cP1LrUfoxJ,
  floating_vue_EIcJ7xiw0h,
  apexCharts_client_ILYcaWERKg,
  clerk_client_82tfzadmlf,
  dateFns_TubOpgHRRM,
  fets_ijYEFpGoED,
  filters_VNgPjx6zHj,
  flags_p3tEupiaZS,
  icons_sncVX745v6,
  lottie_q1asFBKAuo,
  mask_Tk3SUcMqzt,
  money_JsSmk5CwyU,
  pinia_client_l2mvTNw9P0,
  places_wGZnNGyAIQ,
  pusher_T6fDwDIeLY,
  recaptcha_85gNSCNFUU,
  select_tI36lTGDUc,
  vCalendar_client_FEmB2rjZFJ
]