import { z } from 'zod'

export const action_items = z.object({
	nanoid: z.string().trim().min(1),
	entity_nanoid: z.string().trim().min(1),
	title: z.string().trim().min(1),
	description: z.string().trim().min(1),
	status: z.enum(['pending','completed','failed']).optional().default('pending'),
	icon: z.enum(['shield_alt','user','user_shield','user_plus','lock','file_contract','file_signature','id_card','key','exclamation','star','exclamation_circle']),
	created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
})

export const insertable_action_items = z.object({
  nanoid: z.string().trim().min(1),
  entity_nanoid: z.string().trim().min(1),
  title: z.string().trim().min(1),
  description: z.string().trim().min(1),
  status: z.enum(['pending','completed','failed']).optional().default('pending'),
  icon: z.enum(['shield_alt','user','user_shield','user_plus','lock','file_contract','file_signature','id_card','key','exclamation','star','exclamation_circle']),
})

export const updateable_action_items = z.object({
  nanoid: z.string().trim().min(1).optional(),
  entity_nanoid: z.string().trim().min(1).optional(),
  title: z.string().trim().min(1).optional(),
  description: z.string().trim().min(1).optional(),
  status: z.enum(['pending','completed','failed']).optional().default('pending'),
  icon: z.enum(['shield_alt','user','user_shield','user_plus','lock','file_contract','file_signature','id_card','key','exclamation','star','exclamation_circle']).optional(),
})

export const selectable_action_items = z.object({
  nanoid: z.string(),
  entity_nanoid: z.string(),
  title: z.string(),
  description: z.string(),
  status: z.enum(['pending','completed','failed']),
  icon: z.enum(['shield_alt','user','user_shield','user_plus','lock','file_contract','file_signature','id_card','key','exclamation','star','exclamation_circle']),
  created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
})

export type ActionItemsType = z.infer<typeof action_items>
export type InsertableActionItemsType = z.infer<typeof insertable_action_items>
export type UpdateableActionItemsType = z.infer<typeof updateable_action_items>
export type SelectableActionItemsType = z.infer<typeof selectable_action_items>
