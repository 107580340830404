import validate from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/validate.js";
import query_45global from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/middleware/query.global.ts";
import session_45global from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/middleware/session.global.ts";
import workspaces_45global from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/middleware/workspaces.global.ts";
import manifest_45route_45rule from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_typescript@5.4.5_vite@5.4.8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  query_45global,
  session_45global,
  workspaces_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}